<template>
  <!-- interweavenote: Footer -->
  <div class="SwissFooter">
    <footer>
      <h2 class="sr-only">Footer</h2>
      <div class="container-fluid footer-service">
        <h3>©2021 Bundesamt für Bevölkerungsschutz BABS</h3>
        <nav>
          <ul>
            <li>
              <a href="https://github.com/swiss/styleguide/releases/latest">Version 3.2.0</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="container-fluid">
        <hr class="footer-line visible-xs" />
        <img
          class="visible-xs"
          src="@/assets/img/logo-CH.svg"
          onerror="this.onerror=null; this.src='../assets/img/logo-CH.png'"
          alt="back to home"
        />
      </div>
      <div class="footer-address">
        <span class="hidden-xs">©2021 Bundesamt für Bevölkerungsschutz BABS </span>
        <nav class="pull-right">
          <ul>
            <li>
              <span class="text-dimmed" style="font-size: 85%">Rev 10020 </span>
              <a href="http://www.disclaimer.admin.ch/terms_and_conditions.html" target="_blank">Rechtliches</a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  </div>
</template>
