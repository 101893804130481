import { logOut } from "../../auth/auth.service";
import * as types from "./types";
import { loginUserAxios, tokenRefresh } from "@/auth/auth.service";

export async function loginUserAction({ commit }, payload) {
  try {
    const data = await loginUserAxios(payload);
    commit(types.LOGIN_USER, data.internalToken);
    return data.accessToken;
  } catch (e) {}
}

export async function logoutUserAction({ commit }, url) {
  try {
    let logouturl = url.eiam + "realms/vbs_vtg-fub-zkdb/protocol/openid-connect/logout?";
    logouturl = logouturl + "id_token_hint=" + sessionStorage .getItem("id_token");
    logouturl = logouturl + "&post_logout_redirect_uri=" + encodeURIComponent(url.base) + "logout";
    commit(types.LOGOUT_USER);
    window.location.href = logouturl;
  } catch (e) {}
}

// export async function tokenRefreshAction({ commit }) {
export async function tokenRefreshAction({ commit }, payload) {
  try {
    const { data } = await tokenRefresh(payload);
    // console.log(data);
    commit(types.TOKEN_REFRESH, data);
  } catch (e) {}
}
